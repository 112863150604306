import Vue from 'vue'
import axios from '@/libs/axios'
import { prepareProgramRegistrationInfo } from '@core/mixins/prepareProgramRegistrationInfo'
import {
  checkHiddenAttr,
  designateOptions,
  designateValue,
  hasValue,
  transformField
} from '@/helpers/formConditions'

const defaultState = () => {
  return {
    userRole: null,
    roles: [],
    authSettings: null,
    registrationForm: {},
    preparedFields: [],
    allConditions: [],
    hasFileField: false,
    hasSignatureField: false,
    isLoading: false,
    validTracker: false,
    serverMessage: '',
    errorMessage: '',
  }
}


export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getIsLoading: state => state.isLoading,
    getRoles: state => state.roles,
    getUserRole: state => state.userRole,
    getRegistrationForm: state => state.registrationForm,
    getHasFileField: state => state.hasFileField,
    getHasSignatureField: state => state.hasSignatureField,
    getPreparedFields: state => state.preparedFields,
    getAllConditions: state => state.allConditions,
    getValidTracker: state => state.validTracker,
    getServerMessage: state => state.serverMessage,
    getErrorMessage: state => state.errorMessage,
  },
  mutations: {
    SET_USER_ROLE: (state, data) => {
      state.userRole = data
    },
    SET_IS_LOADING: (state, data) => {
      state.isLoading = data
    },
    SET_VALID_TRACKER: (state, data) => {
      state.validTracker = data
    },
    SET_SERVER_MESSAGE: (state, data) => {
      state.serverMessage = data
    },
    SET_ERROR_MESSAGE: (state, data) => {
      state.errorMessage = data
    },
    SET_ROLES: (state, data) => {
      state.roles = data
    },
    SET_AUTH_SETTINGS: (state, data) => {
      state.authSettings = data
    },
    SET_REGISTRATION_FORM: (state, data) => {
      state.registrationForm = data
    },
    SET_FORMS_DATA: state => {
      let index = 0
      state.preparedFields = state.registrationForm.fields.map(field => {
        if (['avatar', 'file_upload'].includes(field.type)) state.hasFileField = true
        if (field.type === 'signature') state.hasSignatureField = true

        const ref = field.name + index
        index++

        return {
          id: field.id,
          ref: ref,
          value: designateValue(field.type, field.default, field.options),
          label: field.label,
          name: field.name,
          options: designateOptions(field.type, field.options),
          type: transformField(field.type),
          required: field.required,
          hidden: checkHiddenAttr(field.conditions.filter(item => item.action === 'Show')),
          use_prefill: field.use_prefill,
          attribute_field_name: field.attribute_field_name,
          password_repeat: null,
        }
      })

      state.allConditions =  state.registrationForm.conditions
    },
    CHECK_CONDITIONS: state => {
      state.allConditions.forEach(condition => {
        let isMatched
        for (let i = 0; i < condition.scoped_conditions.length; i++) {
          const scopeItems = condition.scoped_conditions[i];
          const scopeItemsResults = scopeItems.map(item => {
            const foundedField = state.preparedFields.find(field => field.id === item.field_id)
            return hasValue(item, foundedField.value)
          })
          isMatched = scopeItemsResults.every(i => i === true)
          if (isMatched || i == condition.scoped_conditions.length - 1) {

            if (condition.related_type === 'Field') {
              const field = state.preparedFields.find(field => field.id === condition.related_id)

              if (field) {
                field.hidden = condition.action === 'Show' ? !isMatched : isMatched
                if (!isMatched) {
                  field.value = designateValue(field.type, field.value, field.options)
                }
              }
            }
            break
          }
        }
      })
    },
  },
  actions: {
    prepareRoleOptions(ctx) {
      const isActive = Vue.prototype.$config.enable_student_signup
      const allRoles = ['parent', 'student', 'staff']

      if (!isActive) {
        allRoles.splice(allRoles.indexOf('student'), 1);
      }
      if (!prepareProgramRegistrationInfo.methods.availableRegularRegistration('staff', ctx.state.authSettings)) {
        allRoles.splice(allRoles.indexOf('staff'), 1);
      }
      if (!prepareProgramRegistrationInfo.methods.availableRegularRegistration('parent', ctx.state.authSettings)) {
        allRoles.splice(allRoles.indexOf('parent'), 1);
      }
      ctx.commit('SET_ROLES', allRoles)
    },
    fetchSchoolRegistrationForm(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`school-registration-forms/${queryParams.role}`, {
            params: queryParams.query,
            headers: {
              WithoutSchoolFilter: true,
            }
          })
          .then(response => {
            ctx.commit('SET_REGISTRATION_FORM', response.data.data.form)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}