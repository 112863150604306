import axios from '@/libs/axios'

const defaultState = () => {
  return {
    newChildForm: {},
    newChildFormFields: [],
    isLoading: false,
    validTracker: false,
    serverMessage: '',
    errorMessage: '',
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getIsLoading: state => state.isLoading,
    getNewChildForm: state => state.newChildForm,
    getNewChildFormFields: state => state.newChildFormFields,
    getValidTracker: state => state.validTracker,
    getServerMessage: state => state.serverMessage,
    getErrorMessage: state => state.errorMessage,
  },
  mutations: {
    SET_USER_ROLE: (state, data) => {
      state.userRole = data
    },
    SET_IS_LOADING: (state, data) => {
      state.isLoading = data
    },
    SET_VALID_TRACKER: (state, data) => {
      state.validTracker = data
    },
    SET_SERVER_MESSAGE: (state, data) => {
      state.serverMessage = data
    },
    SET_ERROR_MESSAGE: (state, data) => {
      state.errorMessage = data
    },
    SET_NEW_CHILD_FORM: (state, data) => {
      state.newChildForm = data
    },
    SET_NEW_CHILD_FORM_FIELDS: (state, data) => {
      state.newChildFormFields = data
    },
  },
  actions: {
    fetchSchoolNewChildForm(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('new-child-form', {
            params: queryParams.query,
            headers: {
              WithoutSchoolFilter: true,
            }
          })
          .then(response => {
            ctx.commit('SET_NEW_CHILD_FORM', response.data.data)
            ctx.commit('SET_NEW_CHILD_FORM_FIELDS', response.data.data.fields)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}